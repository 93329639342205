import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import UploadButton from "./UploadButton";
import ExtractDataButton from "./ExtractDataButton";

class AppIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            excelFiles: [],
            pdfFiles: []
        };
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    };

    handleFilesSelected = (files, uploadType) => {
        this.setState({ [uploadType + "Files"]: files });
    };

    render() {
        console.log(this.state)
        return (
           <div style={{ minHeight: "100vh"}}>
               <div style={{ padding: "24px"}}>
                   <div className="row">
                       <div className="col-sm-3">
                           <img src="https://woyera.com/assets/images/image02.png?v=18adae98" alt="Woyera Logo" style={{ width: "200px" }} />
                       </div>
                       <div className="col-sm-6">
                            <div style={{ fontSize: "36px", fontWeight: "700", textAlign: "center", paddingTop: "16px" }}>
                                Extract text from PDFs using AI
                            </div>
                       </div>
                       <div className="col-sm-3">

                       </div>
                   </div>
               </div>
               <div style={{ padding: "48px" }}>
                   <div className="row">
                       <div className="col-sm-3"></div>
                       <div className="col-sm-6">
                           <div className="row">
                               <div className="col-sm-6">
                                   <UploadButton
                                       bgColor="#4CAF50"
                                       textColor="#fff"
                                       text="Upload PDF to Extract From"
                                       accept="application/pdf"
                                       multiple={true}
                                       apiUrl="upload-files/"
                                       onFilesSelected={this.handleFilesSelected}
                                       uploadType="pdf"
                                       errorMsg="There was an error uploading your files. Please try again or email admin@woyera.com for support"
                                       successMsg="Files successfully uploaded"
                                   />
                               </div>
                               <div className="col-sm-6">
                                   <UploadButton
                                       bgColor="#0081ff"
                                       textColor="#fff"
                                       text="Upload Excel Template to Fill Out"
                                       accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                       multiple={true}
                                       apiUrl="upload-files/"
                                       onFilesSelected={this.handleFilesSelected}
                                       uploadType="excel"
                                       errorMsg="There was an error uploading your files. Please try again or email admin@woyera.com for support"
                                       successMsg="Files successfully uploaded"
                                   />
                               </div>
                           </div>
                       </div>
                       <div className="col-sm-3"></div>
                   </div>
               </div>
               <div style={{ padding: "24px"}}>
                   <div className="row">
                       <div className="col-sm-3"></div>
                       <div className="col-sm-6" style={{ textAlign: "center"}}>
                            <input
                                type="email"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                placeholder="Enter your email"
                                style={{ padding: "12px", fontSize: "18px", marginBottom: "20px", width: "70%" }}
                            />
                            <ExtractDataButton textColor="#000" bgColor="#fff" text="Extract Data" info={this.state}/>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {toggleToast: toggleToast}

export default connect(mapStateToProps, mapActionsToProps)(AppIndex)