import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import API_Root from "../Constants/API_Root";


class ExtractDataButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extracting: false
        }
    }

    extractData = (event) => {
        // Update UI to show loading
        this.setState({ extracting: true });

        const showError = () => {
            this.props.toggleToast({
                show: true,
                type: "error",
                message: "There was an error extracting data, please try again"
            })
            this.setState({ extracting: false });
        }
        this.props.toggleToast({
            show: true,
            type: "info",
            message: "Check your email inbox for the results"
        })
        // Send data to the backend
        fetch(API_Root + "extract-data/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.info)
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message: "Data Extracted Successfully"
                    })
                    this.setState({ extracting: false });
                    // Pass file data to parent component
                    console.log(data)
                }).catch(err => {
                    showError()
                })
            } else {
                showError()
            }
        }).catch(err => {
            showError()
        })
    }


    render() {
        const { bgColor, textColor, text } = this.props;
        const { extracting } = this.state;

        return (
            <div>
                    <button
                        style={{ padding: "12px 16px",backgroundColor: bgColor, color: textColor, cursor: "pointer", fontSize: "24px", border: "none"  }}
                        onClick={this.extractData}
                    >
                        {extracting ? "Extracting data..." : text}
                    </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ExtractDataButton)