import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import API_Root from "../Constants/API_Root";


class UploadButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false
        }
    }

    handleFileChange = (event) => {
        const files = event.target.files;
        const { onFilesSelected, apiUrl, uploadType, errorMsg, successMsg } = this.props;

        // Update UI to show loading
        this.setState({ uploading: true });

        // Prepare form data
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });

        const showError = () => {
            this.props.toggleToast({
                show: true,
                type: "error",
                message: errorMsg
            })
            this.setState({ uploading: false });
        }

        // Send data to the backend
        fetch(API_Root + apiUrl + uploadType + "/", {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message: successMsg
                    })
                    this.setState({ uploading: false });
                    // Pass file data to parent component
                    onFilesSelected(data.file_details, uploadType);
                }).catch(err => {
                    console.log(err);
                    showError()
                })
            } else {
                console.log(response.status);
                showError()
            }
        }).catch(err => {
            console.log(err);
            showError()
        })
    }


    render() {
        const { bgColor, textColor, text, accept, multiple } = this.props;
        const { uploading } = this.state;

        return (
            <div>
                <label style={{ padding: "12px 16px",backgroundColor: bgColor, color: textColor, cursor: "pointer" }}>
                    {uploading ? "Uploading..." : text}
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={this.handleFileChange}
                        accept={accept}
                        multiple={multiple}
                    />
                </label>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(UploadButton)