import React from 'react';
import { Route, Switch } from "react-router";
import NotFound from "./SharedComponents/NotFound";
import AppIndex from "./App/AppIndex";

export default ({ childProps }) => {
    return (
        
        <Switch>
            <Route exact path="/" component={AppIndex} />
            <Route exact path="/testing" render={(props) => <AppIndex {...props} showSidebarOnLoad={true} />} />
            <Route exact path="/chatgpt-prompt" render={(props) => <AppIndex {...props} chatgptPromptType={true} />} />
            <Route exact path="/category-prompt" render={(props) => <AppIndex {...props} categoryPromptType={true} />} />
            <Route exact path="/individual-prompt" render={(props) => <AppIndex {...props} individualPromptType={true} />} />
            <Route component={NotFound}/>
        </Switch>
        
    );
};
    


